// src/ChangePasswordScreen.js

import React, { useState, useEffect } from "react";
import { Button, TextField, Grid, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ChangePasswordScreen = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [email, setEmail] = useState(""); // New state for email
    const [user, setUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            const token = localStorage.getItem("token");
            const response = await axios.get("/api/v1/auth/user/me", { headers: { "xc-auth": token } });
            setUser(response.data);
        };
        fetchProfile();
    }, []);

    const handleChangePassword = async () => {
        try {
            const token = localStorage.getItem("token");
            await axios.post(
                "/api/v1/auth/user/password_change",
                { old_password: oldPassword, new_password: newPassword },
                { headers: { "xc-auth": token } }
            );
            navigate("/signin");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.detail) {
                if (typeof error.response.data.detail === "string") {
                    setDialogMessage(error.response.data.detail);
                } else if (error.response.data.detail.msg) {
                    setDialogMessage(error.response.data.detail.msg);
                } else {
                    setDialogMessage("An unknown error occurred.");
                }
            } else {
                setDialogMessage("An unknown error occurred.");
            }
            setOpenDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <Grid container justify="center">
            <Paper style={{ padding: "2em" }}>
                <h2>Change Password for {user.name}</h2>
                <p>ID: {user.id}</p>
                <TextField
                    label="Email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    fullWidth
                    required
                    error={!email}
                    helperText={!email ? "Email is required" : ""}
                />{" "}
                <TextField
                    label="Old Password"
                    type="password"
                    name="old_password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    margin="normal"
                    fullWidth
                />
                <TextField
                    label="New Password"
                    type="password"
                    name="new_password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    margin="normal"
                    fullWidth
                />
                <Button variant="contained" color="primary" onClick={handleChangePassword}>
                    Change Password
                </Button>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Grid>
    );
};

export default ChangePasswordScreen;
