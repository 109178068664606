import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, CardContent, Typography, Grid, Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const ProfileScreen = () => {
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get("/api/v1/auth/user/me", { headers: { "xc-auth": token } });
                setProfile(response.data);
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 400) {
                        // no admin user but user logged in
                        // Invalid or expired token, redirect to sign in page
                        console.log(error.response);
                        localStorage.removeItem("token");
                        navigate("/signin");
                    } else if (error.response.status === 403) {
                        // no user logged in
                        localStorage.removeItem("token");
                        navigate("/signin");
                    } else {
                        throw error;
                    }
                } else {
                    // Other errors, rethrow
                    throw error;
                }
            }
        };
        fetchProfile();
    }, []);

    if (!profile) {
        return <div>Loading...</div>;
    }

    const handleLogout = () => {
        // Add logout logic here
        localStorage.removeItem("token");
        navigate("/signin");
    };
    const handleClose = () => {
        setOpen(false);
        handleLogout();
    };
    const handleDeleteAccount = async () => {
        try {
            const token = localStorage.getItem("token");
            await axios.delete(`/api/v1/auth/user/delete/${profile.id}`, { headers: { "xc-auth": token } });
            localStorage.removeItem("token");
            setOpen(true);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Card style={{ maxWidth: 500, margin: "0 auto", marginTop: 50, padding: 20 }}>
            <CardContent>
                <Box mt={2}>
                    <Grid container justifyContent="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">Name:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle1">{profile.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">Email:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle1">{profile.email}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">ID:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle1">{profile.id}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">group</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="subtitle1">{profile.group}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                {profile.group === "admin" && (
                    <Button variant="contained" color="primary" style={{ marginTop: "20px" }} onClick={() => navigate("/admin")}>
                        User mgt.
                    </Button>
                )}
                <Button variant="contained" color="primary" style={{ marginTop: "20px" }} onClick={() => navigate("/changepassword")}>
                    Change Password
                </Button>
                <Button variant="contained" color="secondary" style={{ marginTop: "20px" }} onClick={handleLogout}>
                    Logout
                </Button>
                <Button variant="contained" color="secondary" style={{ marginTop: "20px" }} onClick={handleDeleteAccount}>
                    Delete Account
                </Button>
            </CardContent>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"User Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>User has been deleted successfully.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default ProfileScreen;
