import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RefreshIcon from "@material-ui/icons/Refresh";
import BlockIcon from "@material-ui/icons/Block";
const AdminScreen = () => {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
    const [openResultDialog, setOpenResultDialog] = useState(false);
    const [resultMessage, setResultMessage] = useState("");

    const [editedUser, setEditedUser] = useState({ password: "" });
    const [newUser, setNewUser] = useState({ name: "", email: "", password: "", group: "", allowed_domain: "" });
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [openNotAdminDialog, setOpenNotAdminDialog] = useState(false);

    const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
    const [tokenStatus, setTokenStatus] = useState(""); // new state for token status

    const ensureUserSelected = (action) => {
        return new Promise((resolve, reject) => {
            if (selectedUsers.length === 0) {
                setResultMessage(`Please select a user to ${action}.`);
                setOpenResultDialog(true);
                reject(); // reject the Promise if no user is selected
            } else {
                resolve(); // resolve the Promise if a user is selected
            }
        });
    };
    const handleOpenDeleteDialog = async () => {
        try {
            await ensureUserSelected("delete");
            setOpenDeleteDialog(true);
        } catch {
            // if ensureUserSelected rejects, stop the function
        }
    };
    const handleAddUser = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post("/api/v1/admin/user/add", newUser, { headers: { "xc-auth": token } });
            const newRow = { id: `${response.data.id}`, ...newUser };
            console.log("response:", response); // Add this line
            console.log("newUser before:", newUser); // Add this line
            console.log("newRow:", newRow); // Add this line
            setRows((rows) => [...rows, newRow]);

            // setRows([...rows, { id: response.data.id, ...newUser }]);

            setOpenAddUserDialog(false);
            setResultMessage("User has been added successfully.");
            console.log(rows);

            setOpenResultDialog(true);
        } catch (error) {
            console.error(error);
            setResultMessage("An error occurred while adding the user.");
        } finally {
            setOpenResultDialog(true);
        }
    };
    const handleDeleteUsers = async () => {
        try {
            const token = localStorage.getItem("token");

            // Gather all user IDs in a list
            let userIdsToDelete = selectedUsers.map((user) => user.id);
            console.log(userIdsToDelete, token);
            // Send a single delete request with the list of user IDs
            await axios.delete(`/api/v1/admin/user/delete/`, {
                data: { user_ids: userIdsToDelete },
                headers: { "xc-auth": token },
            });

            // Update the rows state
            setRows(rows.filter((row) => !userIdsToDelete.includes(row.id)));

            setResultMessage("Users have been deleted successfully.");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 401) {
                localStorage.removeItem("token"); // remove the expired token
                setResultMessage("Your login information has expired. Please log in again."); // set the message
                setOpenResultDialog(true); // show the dialog
                setTimeout(() => {
                    navigate("/signin"); // redirect the user to the login page after showing the dialog for a while
                }, 3000);
            } else {
                setResultMessage("An error occurred while deleting the user.");
                setOpenResultDialog(true);
            }
        } finally {
            setOpenDeleteDialog(false);
            setOpenResultDialog(true);
        }
    };

    const handleEditUser = async () => {
        try {
            await ensureUserSelected("edit");
        } catch {
            return;
        }

        try {
            const token = localStorage.getItem("token");
            console.log(editedUser);
            await axios.put(`/api/v1/admin/user/edit/${editedUser.id}`, editedUser, { headers: { "xc-auth": token } });
            setRows(rows.map((row) => (row.id === editedUser.id ? { ...row, ...editedUser } : row)));
            setOpenEditDialog(false);
            setResultMessage("User has been edited successfully.");
            setOpenResultDialog(true);
        } catch (error) {
            console.error(error);
            setResultMessage("An error occurred while editing the user.");
        } finally {
            setOpenResultDialog(true);
        }
    };

    const handleChangePassword = async () => {
        try {
            await ensureUserSelected("change password");
        } catch {
            return;
        }

        try {
            const token = localStorage.getItem("token");
            await axios.post(
                `/api/v1/admin/user/password_change/${editedUser.id}`,
                { new_password: editedUser.password },
                { headers: { "xc-auth": token } }
            );
            setOpenChangePasswordDialog(false);
            setResultMessage("Password has been changed successfully.");
            setOpenResultDialog(true);
        } catch (error) {
            console.error(error);
            setResultMessage("An error occurred while changing the password.");
        } finally {
            setOpenResultDialog(true);
        }
    };
    // Define the refresh and revoke token handlers
    const handleRefreshToken = async () => {
        try {
            await ensureUserSelected("refresh token");
        } catch {
            return;
        }

        try {
            console.log(selectedUsers);
            const token = localStorage.getItem("token");

            // Prepare the users for the API call
            const users = selectedUsers.map((user) => ({
                user_id: user.id,
                email: user.email,
            }));

            // Make the API call
            const response = await axios.post("/api/v1/admin/token/refresh", users, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Update the status for all selected users
            setRows(rows.map((row) => (selectedUsers.find((user) => user.id === row.id) ? { ...row, token_status: "active" } : row)));

            // Show a success message
            setResultMessage("Token(s) have been refreshed successfully.");
        } catch (error) {
            console.error(error);
            setResultMessage("An error occurred while refreshing the token(s).");
        } finally {
            setOpenResultDialog(true);
        }
    };

    const handleRevokeToken = async () => {
        // ensure at least one user is selected
        if (selectedUsers.length === 0) {
            setResultMessage("Please select at least one user to revoke their token.");
            setOpenResultDialog(true);
            return;
        }

        try {
            const adminToken = localStorage.getItem("token");

            // gather all user IDs in a list
            let userIds = selectedUsers.map((user) => user.id);

            // send a single revoke request with the list of user IDs
            await axios.post(`/api/v1/admin/token/revoke/`, { user_ids: userIds }, { headers: { "xc-auth": adminToken } });

            // update the rows state
            setRows(rows.map((row) => (userIds.includes(row.id) ? { ...row, token_status: "" } : row)));

            setResultMessage("Tokens have been revoked successfully.");
        } catch (error) {
            console.error(error);
            setResultMessage("An error occurred while revoking the token.");
        } finally {
            setOpenRevokeDialog(false);
            setOpenResultDialog(true);
        }
    };
    useEffect(() => {
        console.log(rows);
    }, [rows]);
    useEffect(() => {
        let isActive = true;

        const fetchData = async () => {
            try {
                // fetch user data
                const token = localStorage.getItem("token");
                if (token) {
                    const response = await axios.get("/api/v1/admin/users_with_tokens", { headers: { "xc-auth": token } });

                    // format the data for DataGrid
                    const rowData = response.data.map((user) => ({
                        ...user,
                    }));
                    setRows(rowData);
                } else {
                    throw new Error("No token found");
                }
            } catch (error) {
                if (isActive) {
                    if (error.response && error.response.status === 403) {
                        alert("You are not the admin. Please login again as admin user.");
                        navigate("/profile"); // logged in but no admin
                    } else if (
                        (error.response && (error.response.status === 400 || error.response.status === 401)) ||
                        error.message === "No token found"
                    ) {
                        if (error.response && error.response.status === 400) {
                            console.log("jwt.exceptions.ExpiredSignatureError: Signature has expired");
                            alert("Your login token is expired");
                        }
                        localStorage.removeItem("token");

                        alert("You are not logged in. Please login again as admin user.");
                        navigate("/signin"); //No logged in user
                    }
                }
            }
        };
        fetchData();
        return () => {
            isActive = false;
        };
    }, []);

    const logout = () => {
        // remove token from local storage and redirect to sign in page
        localStorage.removeItem("token");
        navigate("/signin");
    };

    const columns = [
        { field: "id", headerName: "ID", width: 10 },
        { field: "email", headerName: "Email", width: 220 },
        { field: "name", headerName: "Name", width: 130 },
        { field: "group", headerName: "Group", width: 118 },
        { field: "allowed_domain", headerName: "Allowed Domain", width: 200 },
        { field: "created", headerName: "Token Created", width: 160 },
        { field: "last_login", headerName: "Last Login", width: 160 },
        { field: "token_status", headerName: "Token Status", width: 160 },
        // add more columns as needed
    ];

    return (
        <div style={{ height: 400, width: "100%" }}>
            <h1>Admin Panel</h1>
            <Button variant="contained" color="primary" onClick={logout}>
                Logout
            </Button>
            <Tooltip title="Add User">
                <IconButton onClick={() => setOpenAddUserDialog(true)}>
                    <PersonAddIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete Selected Users">
                <IconButton onClick={handleOpenDeleteDialog}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Edit Selected User">
                <IconButton
                    onClick={() => {
                        setEditedUser({ ...selectedUsers[0], password: "" });
                        setOpenEditDialog(true);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Change Password of Selected User">
                <IconButton
                    onClick={() => {
                        setEditedUser({ ...selectedUsers[0], password: "" });
                        setOpenChangePasswordDialog(true);
                    }}
                >
                    <VpnKeyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Refresh Token for Selected User">
                <IconButton onClick={handleRefreshToken}>
                    <RefreshIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Revoke Token for Selected User">
                <IconButton
                    onClick={() => {
                        setEditedUser({ ...selectedUsers[0], password: "" });
                        setOpenRevokeDialog(true);
                    }}
                >
                    {" "}
                    <BlockIcon />
                </IconButton>
            </Tooltip>
            {/* ... */}
            <Dialog open={openResultDialog} onClose={() => setOpenResultDialog(false)}>
                <DialogTitle>Result</DialogTitle>
                <DialogContent>{resultMessage}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenResultDialog(false)} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]} // Add this line
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                    setSelectedUsers(newSelection.map((id) => rows.find((row) => row.id === id)));
                }}
            />
            <Dialog open={openAddUserDialog} onClose={() => setOpenAddUserDialog(false)}>
                <DialogTitle>Add User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        name="name"
                        fullWidth
                        value={newUser.name}
                        onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        name="email"
                        fullWidth
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        fullWidth
                        value={newUser.password}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Group"
                        name="group"
                        fullWidth
                        value={newUser.group}
                        onChange={(e) => setNewUser({ ...newUser, group: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Domains"
                        name="allowed_domain"
                        fullWidth
                        value={newUser.allowed_domain}
                        onChange={(e) => setNewUser({ ...newUser, allowed_domain: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddUserDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddUser} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>Delete Users</DialogTitle>
                <DialogContent>Are you sure you want to delete the selected users?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteUsers} color="primary">
                        Yes, delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Edit User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        name="name"
                        fullWidth
                        value={editedUser ? editedUser.name : ""}
                        onChange={(e) => setEditedUser({ ...editedUser, name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        name="email"
                        fullWidth
                        value={editedUser ? editedUser.email : ""}
                        onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Domains"
                        name="allowed_domain"
                        fullWidth
                        value={editedUser ? editedUser.allowed_domain : ""}
                        onChange={(e) => setEditedUser({ ...editedUser, allowed_domain: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Group"
                        name="group"
                        fullWidth
                        value={editedUser ? editedUser.group : ""}
                        onChange={(e) => setEditedUser({ ...editedUser, group: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditUser} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openChangePasswordDialog} onClose={() => setOpenChangePasswordDialog(false)}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="New Password"
                        fullWidth
                        value={editedUser.password}
                        onChange={(e) => setEditedUser({ ...editedUser, password: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenChangePasswordDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleChangePassword} color="primary">
                        Change Password
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openRevokeDialog} onClose={() => setOpenRevokeDialog(false)}>
                <DialogTitle>Revoke Token</DialogTitle>
                <DialogContent>Are you sure you want to revoke the selected token?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRevokeDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRevokeToken} color="primary">
                        Update Status
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AdminScreen;
