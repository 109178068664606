// src/SignUpScreen.js

import React, { useState } from "react";
import {
    Button,
    TextField,
    Grid,
    Paper,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import axios from "axios";
import { useNavigate } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SignUpScreen = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const navigate = useNavigate();

    const handleSignUp = async () => {
        if (!email || !password || !name) {
            // Check if user ID is provided
            setOpenDialog(true);
            setDialogMessage("All fields are required.");
            return;
        }

        try {
            const response = await axios.post("/api/v1/auth/user/signup", {
                email,
                password,
                name,
            });
            const { data } = response;
            // You may want to save the received token (data.token) to localStorage or context
            setOpenSnackbar(true);
            navigate("/signin");
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.detail) {
                if (typeof error.response.data.detail === "string") {
                    setDialogMessage(error.response.data.detail);
                } else if (error.response.data.detail.msg) {
                    // If the error detail is an object, we assume it has a msg property
                    setDialogMessage(error.response.data.detail.msg);
                } else {
                    setDialogMessage("An unknown error occurred.");
                }
            } else {
                setDialogMessage("An unknown error occurred.");
            }
            setOpenDialog(true);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid container justifyContent="center">
            <Paper style={{ padding: "2em" }}>
                <h2>Sign Up</h2>
                <TextField
                    label="Email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    fullWidth
                    required
                    error={!email}
                    helperText={!email ? "Email is required" : ""}
                />
                <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    fullWidth
                    required
                    error={!password}
                    helperText={!password ? "Password is required" : ""}
                />
                <TextField
                    label="Name"
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    margin="normal"
                    fullWidth
                    required
                    error={!name}
                    helperText={!name ? "Name is required" : ""}
                />
                <Button variant="contained" color="primary" onClick={handleSignUp}>
                    Sign Up
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => navigate("/signin")}>
                    Sign In
                </Button>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        Signup successful!
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" style={{ display: "flex", alignItems: "center" }}>
                        <IconButton color="secondary">
                            <ErrorOutlineIcon />
                        </IconButton>
                        {"Error"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Grid>
    );
};

export default SignUpScreen;
