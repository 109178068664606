// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AdminScreen from "./AdminScreen";
import SignUpScreen from "./SignUpScreen";
import SignInScreen from "./SignInScreen";
import ProfileScreen from "./ProfileScreen";
import ChangePasswordScreen from "./ChangePasswordScreen";
import SignOutButton from "./SignOutButton";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/signin" />} />
                <Route path="/admin" element={<AdminScreen />} />
                <Route path="/signup" element={<SignUpScreen />} />
                <Route path="/signin" element={<SignInScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/changepassword" element={<ChangePasswordScreen />} />
            </Routes>
        </Router>
    );
}

export default App;
